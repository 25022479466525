<template>
  <base-section
    id="featuresInvert"
    class="blue-grey darken-4"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container
        fluid
      >
        <v-row>
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          icon: 'mdi-lan-check',
          title: 'Создание и управление инфраструктурой',
          text: 'Поможем спроектировать и модернизировать ИТ-инфраструктуру, настроить непрерывный мониторинг. Подскажем, как снизить затраты, увеличить производительность и обеспечить доступность вашего бизнеса.\n',
        },
        {
          icon: 'mdi-devices',
          title: 'Интеграция систем',
          text: 'Поможем интегрировать вашу бизнес-систему с вендорной информационной системой.',
        },
        {
          icon: 'mdi-database-search-outline',
          title: 'Бизнес-анализ и консультирование',
          text: 'Проведем полный анализ бизнес-процессов вашей организации, поможем выявить слабые места и оптимизировать избыточные задачи\n',
        },
        {
          icon: 'mdi-face-woman-shimmer-outline',
          title: 'Поддержка и сопровождение',
          text: 'Осуществляем настройку, администрирование и сопровождение операционных систем, облачных решений, баз данных, и некоторых вендорных информационных систем',
        },
      ],
    }),
  }
</script>
